.projects__container {
    grid-template-columns: repeat(3, 270px);
    justify-content: center;
    column-gap: 1.8rem;
}
.projects__content {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--container-color);
    padding: 2.5rem 0 2rem 2.5rem;
}
.projects__icon {
    display: block;
    font-size: 2rem;
    color: var(--title-color);
    margin-bottom: var(--mb-1);
}
.projects__title {
    font-size: var(--h3-font-size);
    margin-bottom: var(--mb-1);
    font-weight: var(--font-medium);
}
.projects__button {
    color: var(--title-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    cursor: pointer;
}
.projects__button-icon {
    font-size: 1rem;
    transition: 0.3s;
}
.projects__button:hover .projects__button-icon {
    transform: translateX(0.5rem);
}
.projects__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
}
.project__link {
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
}
.project__link-button {
    padding: 3%;
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    border-radius: 20px;
    opacity: 1;
}
.project__link-button:hover {
    background-color: rgba(0, 0, 0, 1);
    padding: 3.1%;
}
.portfolio {
    background : url('../../assets/portfolio.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.metube {
    background : url('../../assets/metube.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.CTTS {
    background : url('../../assets/CTTS.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}
.FCC {
    background : url('../../assets/FCC.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}
.MAXCASH {
    background : url('../../assets/MAXCASH.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}
.GOL {
    background : url('../../assets/GOL.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}
.BB {
    background : url('../../assets/BB.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}
.EASYM {
    background : url('../../assets/EASYM.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}
.techpunch {
    background : url('../../assets/techpunch.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.tejassupliers {
    background : url('../../assets/tejassuppliers.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.eXchange {
    background : url('../../assets/eXchange.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.todoApp {
    background : url('../../assets/todolist.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.projects__modal-content {
    width: 500px;
    position: relative;
    background-color: var(--container-color);
    padding: 4.5rem 2.5rem 2.5rem;
    border-radius: 1.5rem;
}
.projects__modal-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
}
.projects__modal-title,
.projects__modal-description {
    text-align: center;
}
.projects__modal-title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1);
}
.projects__modal-description {
    font-size: var(--small-font-size);
    padding: 0 3.5rem;
    margin-bottom: var(--mb-2);
}
.projects__modal-projects {
    row-gap: 0.75rem;
}
.projects__modal-service {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
}
.projects__modal-icon {
    color: var(--title-color);
    font-size: 1.1rem;
}
.projects__modal-info {
    font-size: var(--small-font-size);
}
.active-modal {
    opacity: 1;
    visibility: visible;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .projects__container {
        grid-template-columns: repeat(3, 218px);
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .projects__container {
        grid-template-columns: repeat(3, 150px);
    }
    .projects__content {
        padding: 1.25rem 0.5rem 1.25rem 1.5rem;
    }
    .projects__icon {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 576px) {
    .projects__container {
        grid-template-columns: repeat(2, 1fr);
    }
    .projects__modal-content {
        padding: 4.5rem 1.5rem 2.5rem;
    }
    .projects__modal-description {
        padding: 0;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .projects__container {
        grid-template-columns: max-content;
    }
    .projects__content {
        padding-right: 6rem;
    }
}